@import "../variables";

.Login {
  .loginField {
    label {
      font-weight: bold;
      color: $secondaryColor;
      font-size: 1.25em;
    }

    input {
      font-size: 1.25em;
      width: 100%;
    }
  }

  .loginContent {
    @include md {
      width: 80%;
    }
  }

  .loginButtons {
    display: flex;
    flex-direction: column;

    @include sm {
      flex-direction: row;
      justify-content: flex-end;
    }
  }

  button {
    width: 100%;

    @include sm {
      width: auto;
    }

    &.submitLogin {
      margin-left: 0;
      margin-top: 10px;
      order: 1;

      @include sm {
        margin-left: 10px;
        order: 2;
      }
    }
  }

  .buttonRegister {
    order: 2;

    @include sm {
      order: 1;
    }
  }
}
