@import "../../variables";

.ShopTile {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  background: #fff;
  position: relative;
  height: 100%;
  border: 1px solid #eee;

  & > * {
    margin-top: 10px;
  }

  &-image-wrapper {
    width: 100%;
    padding: 0 30px;
    text-align: center;
    height: 110px;
    overflow: hidden;
  }

  .imageLink {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  &-content {
    text-align: center;
    letter-spacing: 0;
    font-size: $fontSizeDefault;
    line-height: 150%;
    margin-bottom: 15px;
    margin-top: 25px;

    span {
      color: $secondaryColor;
    }

    h3 {
      display: none;
    }
  }

  &-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1em;
    width: 100%;
    flex-grow: 1;

    & > a.more {
      font-size: $fontSizeDefault;
      margin-bottom: 0.5em;
      margin-top: 0.5em;
      cursor: pointer;
      color: $primaryColor;
      text-decoration: none !important;

      svg {
        color: $primaryColor;
      }
    }

    .MuiButton-root.MuiButton-contained {
      margin-top: 1.5em;
    }
  }
  &-content {
    width: 100%;
    flex-grow: 1;
  }

  &-star {
    position: absolute;
    top: 10px;
    right: 10px;
    color: $primaryColor;
    cursor: pointer;

    svg {
      font-size: 20px;
    }
  }
}
