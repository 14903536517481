@import "../../variables";

.InputWithButton {
  width: 100%;

  display: flex;
  justify-content: space-between;

  .MuiInputBase-root {
    padding: 0 15px;
    line-height: 44px;
    flex: 1;
    color: $secondaryColor;
    font-weight: 300;
    border: 1px solid $secondaryColor;
    background: #fff;
    margin-right: 0.5em;

    & input::placeholder {
      opacity: 1;
    }
  }
}
