@import "../../variables";

.navigation {
  z-index: 20;
  background: #fff;
  text-transform: uppercase;
  position: relative;
  font-family: $fontDefault;

  .nav-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.1rem;
    padding: 0 18px;

    .nav-item-list {
      flex-grow: 1;

      nav {
        width: 100%;
        display: flex;
        align-items: center;

        a {
          color: $secondaryColor;
          display: inline-block;
          padding: 12px;
          margin-left: 8px;

          &.active {
            border-top: 1px solid $primaryColor;
            color: $secondaryColor;
            background: #fff;
          }
        }
      }
    }
  }

  .subnav {
    background: #e4eff7;
    min-height: 20px;
  }

  .login {
    svg {
      margin-right: 6px;
    }
  }

  .logo {
    padding-left: 0;

    + .nav-item-list {
      text-align: right;
      a {
        margin-left: 40px;
        text-transform: none;
        &:first-child {
          color: $secondaryColor;
        }
      }
    }

    @include md {
      margin-left: 2px;
    }

    a {
      color: $textColor;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1.1;
      font-weight: 600;
      font-size: 12px;
      letter-spacing: 0.1px;
      margin: 25px 0;

      @include md {
        margin: 25px 0;
      }

      span {
        display: none;

        @include md {
          display: inline;
        }
      }

      img {
        height: 28px;
        margin-left: 0;
      }
    }
  }

  @include lg {
    .burger {
      display: none;
    }
  }

  @media (max-width: #{$breakpoint-lg}) {
    & > .logo {
      align-self: flex-start;
      flex: 1;
    }
  }
}
