@import "../../variables";

.MuiExpansionPanelSummary-root.MuiExpansionPanelSummary-root {
  padding: 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;

  &.Mui-expanded {
    border-bottom: 0 none;
  }

  .MuiExpansionPanelSummary-content {
    display: flex;
    align-items: center;

    .remove {
      display: none;
    }

    .MuiIcon-root {
      margin-right: 10px;
    }

    &.Mui-expanded {
      .add {
        display: none;
      }

      .remove {
        display: block;
      }
    }
  }

  h3 {
    padding: 0;
    margin: 0;
    font-weight: 100;
  }

  &.Mui-expanded {
    h3 {
      color: $secondaryColor;
    }
  }
}

.MuiCollapse-wrapperInner {
  .MuiExpansionPanelDetails-root {
    padding: 0 0 16px;
    border-bottom: 1px solid #eee;
    margin-bottom: -1px;
    flex-direction: column;
    max-width: 100%;

    @include md {
      max-width: 70%;
    }
  }
}
