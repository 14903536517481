@import "../../variables";

.HeroBox {
  width: 100%;
  margin: 0 auto !important;
  background: #fff;
  padding: 2rem;
  position: relative;
  border: 1px solid #e4e4e4;

  .cashbackFilterBoxCombinedTitle {
    @media (min-width: 1280px) {
      text-align: right;
      padding-left: 0;
    }
    padding-right: 10px;
  }

  h1 {
    span {
      display: block;
      font-weight: 300;
    }
  }

  h3 {
    color: $primaryColor;
    @media (max-width: 1279px) {
      margin: 0;
    }
    &.products {
      margin: 7px 0;
    }
  }

  & > div.HeroBox-main {
    display: flex;
    flex-direction: row;

    @media (min-width: 600px) {
      padding-top: 15px;
    }

    input {
      font: 300 1.2rem $fontDefault;
      color: #707070;
      width: 100%;
      background: none;
      outline: none;
      border: none;
      letter-spacing: 0.05rem;

      @include lg {
        font: 300 1.2rem $fontDefault;
        padding: 0;
      }
      &::placeholder {
        color: #707070;
        letter-spacing: 0.05rem;
      }
    }

    button {
      padding: 8px 40px;
      background: $primaryColor;
      border-radius: 100px;
      color: #fff;
      outline: none;
      border: none;
      border-left: 1px solid #ccc;
      font: 400 1rem/26px $fontDefault;
      letter-spacing: 1px;
      cursor: pointer;

      @include lg {
        padding: 15px 70px;
        font: 400 1.3rem/26px $fontDefault;
      }
    }
  }

  & > div.HeroBox-children {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-top: 25px;

    & > * {
      margin-left: 10px;
      margin-bottom: 10px;
      cursor: pointer;

      @include lg {
        margin-bottom: 0;
      }

      &:hover {
        background: #ffffff30 !important;
      }
    }

    button {
      font-size: 14px;
      color: #707070;
      border: 1px solid #ccc;
      border-radius: 0;
      background: none;
      outline: none;
      padding: 15px;

      &.active {
        background: $primaryColor;
        border: 1px solid $primaryColor;
        color: #fff;

        &:hover {
          background: $primaryColor !important;
          color: #fff;
        }
      }
    }

    .filter {
      button {
        padding: 8px 15px;

        &.MuiButton-contained {
          background: $primaryColor;
          color: #fff;
          border-color: $primaryColor;
        }
      }
    }

    .MuiInput-root {
      border: 1px solid $primaryColor;
      border-radius: 50px;
      background: none;
      outline: none;
      padding: 0;
      color: #707070;
      min-width: 200px;
      font-size: 14px;

      & > div {
        padding: 10px 20px;
        width: 100%;
      }

      .MuiSelect-select:focus {
        background: none;
      }

      &:before,
      &:after {
        display: none;
      }

      & > svg {
        color: #fff;
        right: 12px;
      }
    }
  }

  .sorting {
    width: 100%;
    padding: 0 8px;

    @include sm {
      width: 250px;
      padding: 0;
      margin-top: 10px;
    }
  }

  .modeSwitch {
    padding: 0;
    margin-top: 8px;

    button {
      margin: 8px;
    }
  }

  .MuiInput-root.rounded-select {
    width: 100%;
    margin: 0;

    @include sm {
      width: calc(100% - 74px);
      margin: 0 8px;
    }

    &.sorting-select {
      width: 100%;
      margin: 0;

      @include sm {
        width: calc(100% - 74px);
        margin: 0 8px;
      }
    }
  }
}

.CashbackFilterBox {
  padding: 40px 0;
  .quickFilters {
    .MuiButtonBase-root {
      color: #fff;
    }
    .MuiButton-outlined {
      background: $secondaryColor;
      border-color: $secondaryColor;
    }
  }
}
