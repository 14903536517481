@import "../../variables";

.DownloadList {
  list-style: none;
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    list-style: none;
    list-style-type: none;
    margin: 0 0 20px 0;
    padding: 0;

    a,
    a:visited,
    a:active,
    a:focus,
    a:hover {
      color: $primaryColor;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      h3 {
        margin: 0;
      }
    }
  }
}
