@import '../../variables';

.Transactions {
  .responsiveTable tbody tr:first-child td {
    border-top: none;
    font-weight: normal;
  }

  .responsiveTable th {
    padding: 8px 8px 16px 8px;
  }

  .MuiPaper-root {
    .MuiTable-root.MuiTable-root td {
      height: auto !important;
    }
  }

  h2,
  h3 {
    font-weight: normal;
    color: $primaryColor;
  }

  .tableFilter {
    display: flex;
    flex-direction: column;

    .MuiInput-root.rounded-select .MuiSelect-select {
      font-family: Arial, Helvetica, sans-serif;
    }

    & > div {
      margin: 0 0 16px;
      width: 100%;

      .MuiInput-root.rounded-select {
        width: 100%;
        margin: 0;
      }
    }

    @include sm {
      flex-direction: row;

      & > div {
        margin: 0 8px 30px;
      }

      & > div:first-child {
        margin-left: 0;
      }

      & > div:last-child {
        margin-right: 0;
      }
    }
  }
}
