@import "../../variables";

.hero {
  position: relative;
  margin-bottom: 7rem;
  height: 670px;

  &.home {
    height: 50vh;

    @include sm {
      height: 670px;
    }
  }

  h1 {
    color: $primaryColor;
    font-weight: 400;
  }

  .image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-position: top center;
    background-size: cover;
  }

  .heroContainer {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: auto;
  }
}
