@import "../../variables";

.ImageSlider {
  margin-bottom: 15px;
  background: transparent;

  .slide {
    width: 100%;
    padding-top: 33%;
    background-position: center center;
    background-size: cover;
    position: relative;
  }

  .swiper-pagination-bullet-active {
    background: $secondaryColor !important;
  }

  .swiper-pagination-bullet {
    background-color: #eee;
    opacity: 0.9;
    border: 1px solid #eee;
  }

  .content-box {
    position: absolute;
    bottom: 50px;
    right: 50px;
    background: rgba(255, 255, 255, 0.9);
    padding: 24px;
    max-width: 30%;
    line-height: 1.25;

    h4 {
      margin: 0;
      font-weight: 600;
      color: $secondaryColor;
    }

    h2 {
      font-size: 2rem;
      margin: 0 0 1rem;
      color: $secondaryColor;
      line-height: 1;
    }

    p {
      margin-top: 0;
      font-family: $fontDefault;
      font-size: 18px;
      font-weight: 100;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: #{$breakpoint-md}) {
    .slide {
      padding-top: 50%;
    }

    .content-box {
      position: static;
      transform: none;
      top: 0;
      max-width: 100%;
      background: #e4eff7;
    }
  }
}
