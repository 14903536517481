$breakpoint-xs: "0px";
$breakpoint-sm: "600px";
$breakpoint-md: "1140px";
$breakpoint-lg: "1280px";
$breakpoint-xl: "1920px";

@mixin xs {
  @media (min-width: #{$breakpoint-sm}) {
    @content;
  }
}

@mixin sm {
  @media (min-width: #{$breakpoint-sm}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{$breakpoint-md}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$breakpoint-lg}) {
    @content;
  }
}

@mixin xl {
  @media (min-width: #{$breakpoint-xl}) {
    @content;
  }
}

$primaryColor: #eb6507;
$secondaryColor: #0b5aac;
$textColor: #333;
$lightTextColor: #737373;
$highlightColor: #009fd6;
$borderColor: #f0f0f0;
$lightGreenBackground: #e4eff7;

$starColor: #fcc200;

$fontDefault: "segoe", sans-serif;
$fontSpecial: "segoe", sans-serif;

$fontSizeDefault: 1.15rem;
$fontSizeSmall: 0.857rem;
$fontSizeExtraSmall: 0.714rem;

$fontSizeHeadline1: 2rem;
$fontSizeHeadline2: 1.5rem;
$fontSizeHeadline3: 1.25rem;
$fontSizeHeadline4: 0.9rem;

$boxPadding: 30px 30px;
