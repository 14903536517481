@import "../../variables";

.Security,
.service-hilfe {
  .disabled {
    opacity: 0.5;
  }
  .cms-content-box {
    position: relative;
    padding-bottom: 50px !important;

    div:first-child {
      height: 170px !important;
      @media (min-width: 600px) {
        height: 330px !important;
      }
    }

    h2 {
      color: $secondaryColor;
      margin: 0 0 10px;
    }

    p {
      p {
        &:last-of-type {
          strong {
            position: absolute;
            bottom: 25px;
          }
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
      line-height: 1.5;
      strong {
        color: $secondaryColor;
      }
    }
  }
}
