@import "../../variables";

.ShopDetail {
  h1 {
    color: $primaryColor;
    font-weight: 400;
  }
  h2 {
    font-weight: 100;
    color: $primaryColor;
  }

  &-vouchers {
    & > div:first-child {
      // margin-top: 0;
      padding-top: 0;

      & > button {
        top: -0.5em;
      }
    }

    & > div:last-child {
      border: none;
      padding-bottom: 0;
    }
  }

  strong {
    color: $primaryColor;
  }

  &-voucher {
    border-bottom: 1px solid #f0f0f0;
    padding-top: 1em;
    padding-bottom: 2em;
    margin-top: 30px;

    .voucherIntro {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }

    .voucherDescription {
      max-width: 100%;
      margin-bottom: 20px;

      @include md {
        max-width: 70%;
      }
    }

    & > button,
    & > a {
      float: right;
      position: relative;
      top: 1em;
      color: #fff;
      border-color: $primaryColor;
      border-style: solid;

      &.MuiButton-containedPrimary {
        margin-top: -15px;

        span.MuiButton-label {
          color: #fff !important;
        }
      }

      span {
        color: $primaryColor !important;
      }
    }

    p {
      max-width: 700px;
    }

    h3 {
      color: $primaryColor;
      margin-bottom: 1.5em;
    }

    &-info {
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      display: flex;
      font-weight: 300;
    }
  }

  .color-secondary {
    color: $secondaryColor;
  }
}
