@import "../../variables";

.Home {
  @media (max-width: #{$breakpoint-md}) {
    .onlyMobile {
      .Headline {
        font-size: 40px;
        &.secondary {
          font-size: 25px;
        }
      }
    }
  }

  .twoColorLink,
  .twoColorLink:visited,
  .twoColorLink:focus,
  .twoColorLink:active,
  .twoColorLink:hover {
    color: #000000;
  }

  .extensionTeaser {
    .Box {
      @include sm {
        margin-left: 16px;
      }
    }
  }
}
