@import "../../variables";

.filter {
  .Box {
    border: 1px solid #e4e4e4;
  }

  .MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary {
    border: 1px solid #ccc;
    padding: 8px 15px !important;
    margin: 8px;

    .MuiButton-label {
      font-size: 1rem;
      color: #707070;
    }
  }

  .MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary {
    padding: 8px 15px !important;
    border: 1px solid $primaryColor;
    margin: 8px;
  }
}

.MuiInput-root.rounded-select {
  border-color: $secondaryColor !important;
  width: 100%;
  margin: 0;

  .MuiSelect-select {
    color: $secondaryColor;
    font-family: $fontDefault;
    font-weight: 300;
    padding: 13px 15px !important;
    font-size: 1rem;
  }
}

.resetFilterWrapper {
  text-align: right;
  padding-top: 8px;

  .resetFilterButton {
    font-family: $fontDefault;
    font-size: 1.15rem;
    color: $primaryColor;
    cursor: pointer;
  }
}

.combined {
  .HeroBox {
    .modeSwitch {
      button {
        margin-right: 5px;
        padding: 12px 15px;
        background: none;
        box-shadow: none;
        font-family: $fontDefault;
        font-weight: 300;
        font-size: 14px;
        border: 1px solid #ccc;
        color: #707070;
        margin-top: 5px;

        @include md {
          margin-top: 0;
        }

        &.active {
          background: $primaryColor;
          border-color: $primaryColor;
          color: #fff;
        }
      }
    }
  }

  .shops-list {
    margin: 0 0 0 -7px !important;
  }

  .products-list {
    border-top: 3px solid #e7e7e7;
    padding-top: 30px;
    margin-top: 40px !important;
  }
}

.shops {
  .shops-list {
    margin-top: 0 !important;
  }
}

.products {
  .shops-list {
    display: none;
  }
  .products-list {
    margin-top: 0 !important;
  }
}

.filterHolder {
  margin-bottom: 1em;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @include md {
    flex-direction: row;
  }
}

table {
  box-shadow: none !important;
}

.goToShopPanel {
  width: 100%;
}

.shopTypeSwitchWrapper {
  display: flex;
  flex-direction: row;
  padding: 0 0 16px 0;

  .shopTypeSwitch {
    flex-grow: 1;
    padding: 5px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    button {
      padding: 11px;
      width: 100%;
      background: $secondaryColor;
      color: #fff;
      .MuiButton-label {
        font-weight: 100 !important;
        font-size: 1.75em !important;
      }
    }

    &.active {
      button {
        background-color: $primaryColor;

        &:before {
          content: "";
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          position: absolute;
          bottom: -15px;
          left: 50%;
          margin-left: -10px;
          border-top: 15px solid $primaryColor;
        }
      }
    }
  }
}

.priceRangeFilter {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;

  h4.Headline {
    flex-grow: 0;
  }

  .MuiSlider-root {
    max-width: 50%;
  }
}

.MuiButton-root.MuiButton-outlined.filterButton {
  margin-right: 0;
  width: 100%;

  .MuiButton-label {
    font-size: 13px;
  }

  &:nth-child(odd) {
    margin-right: 8px;
  }

  @include sm {
    width: auto;
    margin-right: 8px;

    .MuiButton-label {
      font-size: 15px;
    }

    &:nth-child(odd) {
      margin-right: 8px;
    }
  }
}

.cms-content-box {
  position: relative;
  padding-bottom: 50px !important;
  p {
    p {
      &:last-of-type {
        strong {
          position: absolute;
          bottom: 25px;
        }
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
    line-height: 1.5;
    strong {
      color: $secondaryColor;
    }
  }
}
