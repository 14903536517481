@import "../../variables";

.Backlink {
  position: relative;
  font-size: 1rem;
  padding-bottom: 30px;

  a {
    text-decoration: none;
    color: $primaryColor;
    cursor: pointer;

    &.black {
      color: #000000;
    }
  }

  svg {
    margin-right: 6px;
  }
}
