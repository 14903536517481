@import "../../variables";

h1,
h2,
h3,
h4,
h5,
h6 {
  &.Headline {
    color: #000000;
    flex-grow: 1;
    font-family: "segoe", sans-serif;
    margin-top: 0;

    span {
      font-family: "segoe", sans-serif;
    }

    p {
      margin: 0;
    }

    a,
    a:visited,
    a:focus,
    a:active,
    a:hover {
      color: "#000000";
      font-family: "segoe", sans-serif;
    }

    &.primary {
      color: $primaryColor;

      a,
      a:visited,
      a:focus,
      a:active,
      a:hover {
        color: $primaryColor;
      }
    }

    &.centered {
      text-align: center;

      &.primary {
        padding-bottom: 0.5em;
        border-bottom: 1px solid $primaryColor;
      }
    }

    &.secondary {
      color: $secondaryColor;

      a,
      a:visited,
      a:focus,
      a:active,
      a:hover {
        color: $secondaryColor;
      }
    }

    &.white {
      color: #ffffff;

      a,
      a:visited,
      a:focus,
      a:active,
      a:hover {
        color: #ffffff;
      }
    }
  }
}

h1.Headline {
  font-size: $fontSizeHeadline1;
  font-weight: normal;

  @include md {
    font-size: calc(#{$fontSizeHeadline1} * 1.25);
  }

  &.small {
    font-size: $fontSizeHeadline2;
  }
}

h2.Headline {
  font-size: $fontSizeHeadline2;
  margin-bottom: 1em;
  font-weight: normal;

  @include md {
    font-size: calc(#{$fontSizeHeadline2} * 1.25);
  }

  &.small {
    font-size: $fontSizeHeadline3;
  }
}

h3.Headline {
  font-size: $fontSizeHeadline3;
  @include md {
    font-size: calc(#{$fontSizeHeadline3} * 1.25);
  }

  &.small {
    font-size: $fontSizeHeadline4;
  }
}

h4.Headline {
  font-size: $fontSizeHeadline4;
  @include md {
    font-size: calc(#{$fontSizeHeadline4} * 1.25);
  }
}

h1.thin,
h2.thin,
h3.thin,
h4.thin,
h5.thin,
h6.thin {
  font-weight: normal;
}

h1.skinny,
h2.skinny,
h3.skinny,
h4.skinny,
h5.skinny,
h6.skinny {
  font-weight: 100;
}
