@import "../../variables";

.SpecialTile {
  background: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;

  h2 {
    color: $primaryColor;
    font-size: 1.5rem;
    font-weight: 300;
    margin-bottom: 20px;
  }

  & > img {
    width: 100%;
    min-height: 1px;
  }

  & > div.SpecialTile-main {
    position: relative;
    padding: 20px 15px 70px;
    width: 100%;

    @include sm {
      padding: 30px 30px 70px;
    }

    & > .SpecialTile-priceBefore {
      text-decoration: line-through;
      color: #333;
    }

    & > .SpecialTile-actualPrice {
      margin-top: -5px !important;
      font-size: 1.25rem;
    }

    & > .SpecialTile-subTitle {
      font-weight: bold;
      font-size: $fontSizeHeadline3;
    }

    & > div.SpecialTile-content {
      line-height: 1.6;
      margin-bottom: 20px;
      flex-grow: 1;
      margin-top: 10px;
      font-size: 12px;
    }
  }

  & > div.SpecialTile-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 15px 30px;
    height: 70px;
    color: $primaryColor;

    @include sm {
      padding: 0 30px 30px;
    }

    div.SpecialTile-info {
      font-weight: 300;
      color: $primaryColor;
      padding-top: 7px;
      font-size: 1.2rem;
    }
  }

  .MuiButton-root {
    width: auto;
    float: right;
  }
}
