@import '../../variables';

.Dashboard {
  .title > h2 {
    margin-top: 2.5rem;
    font-weight: normal;
    color: $primaryColor;
  }
  h2,
  h3 {
    font-weight: normal;
    color: $primaryColor;
  }

  .responsiveTable tbody tr:first-child td {
    font-weight: normal;
  }

  .responsiveTable th {
    padding: 8px 8px 16px 8px;
  }
}
