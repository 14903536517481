@import "variables";
@import "typography";

html {
  height: 100%;
}

body,
#root {
  min-height: 100vh;
}

html,
body {
  font-weight: normal;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background: #ffffff;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: $primaryColor;
}

textarea {
  width: 100%;
  border-color: #0000003b;
  padding: 18.5px 14px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.1876em;
  &:hover {
    border-color: #000;
  }
  &:focus {
    outline: 2px solid $primaryColor;
    outline-offset: -2px;
  }
}

input:-internal-autofill-selected {
  padding-left: 8px;
  background: #ffffff22;
}

.logo img {
  height: 50px;
}

.MuiButton-root {
  box-shadow: none;
  padding: 10px 25px;
  text-decoration: none;
  border-radius: 0 !important;

  &:hover {
    box-shadow: none !important;
  }

  &.MuiButton-containedPrimary {
    color: #ffffff;
  }

  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none !important;
  }

  .MuiButton-label {
    font-family: $fontDefault;
    font-weight: 300;
    text-transform: none;
    text-decoration: none !important;

    svg:first-child:not(:last-child) {
      margin-right: 8px;
    }

    svg:last-child:not(:first-child) {
      margin-left: 8px;
    }

    &:hover,
    &:visited,
    &:link,
    &:active {
      text-decoration: none !important;
    }
  }

  &.MuiButton-contained {
    box-shadow: none;
  }

  &.MuiButton-outlined {
    padding: 6px 15px;
    border: 1px solid;

    @include lg {
      padding: 6px 35px;
    }

    &:hover {
      border: 1px solid;
    }

    .MuiButton-label {
      font-weight: 300;
      font-size: 1.2rem;
    }
  }

  &.MuiButton-contained {
    padding: 8px 20px;
  }

  &.MuiButton-sizeSmall {
    padding: 6px 10px;

    .MuiButton-label {
      font-weight: 300;
    }
  }

  &.MuiButton-outlinedPrimary {
    border-color: $primaryColor;
  }
}

.MuiPaper-rounded {
  border-radius: 0 !important;
}

.MuiInput-root.rounded-select {
  border: 1px solid $secondaryColor;
  background: none;
  outline: none;
  color: $lightTextColor;
  min-width: 150px;
  font-size: $fontSizeDefault;
  padding: 0;

  & > div {
    width: 100%;
  }

  .MuiSelect-select {
    font-size: $fontSizeDefault;
    color: $lightTextColor;
    padding: 14px 40px 14px 0;
  }

  .MuiSelect-select:focus {
    background: none;
  }

  &:before,
  &:after {
    display: none;
  }

  &:before {
    position: absolute;
    color: $primaryColor;
    right: 10px;
    top: 8px;
    left: auto;
    content: url("/assets/images/icons/arrow-down.svg");
    display: block;
    border: 0 !important;
  }

  & > svg {
    display: none;
  }
}

.align-center {
  text-align: center;
}

.color-primary {
  color: $primaryColor;
}

.color-good {
  color: #097b00;
}

.color-bad {
  color: #c02b33;
}

table.MuiTable-root.MuiTable-root {
  // ---- fix for mui-datatable mobile view: https://github.com/gregnb/mui-datatables/issues/530
  [class*="MUIDataTableBodyRow-responsiveStacked-"] {
    display: flex;
    flex-wrap: wrap;

    td {
      flex-basis: 50%;
      display: inline-flex;
      align-items: center;
    }
  }

  @include md {
    [class*="MUIDataTableBodyRow-responsiveStacked-"] {
      display: table-row;

      td {
        display: table-cell;
        flex-basis: auto;

        &[class*="MUIDataTableBodyCell-cellHide-"] {
          display: none;
        }
      }
    }
  }
  // ----

  th,
  td {
    font-family: $fontDefault;
    font-weight: 300;
    font-size: $fontSizeDefault;
    border: 0;
  }

  thead {
    th {
      border-bottom: 1px solid $primaryColor;
      font-weight: 900;
      color: $primaryColor;
    }
  }

  tbody {
    tr:nth-child(2n + 1) {
      background: #f7f7f7;
    }

    border-bottom: 1px solid #e4e4e4;
  }
}

[class*="MUIDataTable-paper"].MuiPaper-elevation4 {
  box-shadow: none;
}

a.MuiLink-root {
  color: currentColor;
  text-decoration: none !important;
  border-bottom: 1px solid $primaryColor;
}

.MuiSlider-valueLabel {
  transform: scale(0.8) translateY(-10px) !important;

  & > span > span {
    font-size: 15px;
  }
}

a {
  cursor: pointer;
}

.logo {
  padding-left: 10px;

  @include lg {
    padding: 0;
  }

  a {
    border: 0 !important;
  }
}

.direction-xs-column-reverse {
  flex-direction: column-reverse;
}

.direction-xs-row-reverse {
  flex-direction: row-reverse;
}

.direction-lg-row {
  @include lg {
    flex-direction: row;
  }
}

.direction-lg-column {
  @include lg {
    flex-direction: column;
  }
}

@media (max-width: #{$breakpoint-md}) {
  .MuiInput-root.rounded-select .MuiSelect-select {
    padding: 6px 10px;
    width: 80%;
  }

  .MuiInput-root.rounded-select {
    min-width: 70px;
  }

  .MuiInput-root.rounded-select > svg {
    top: 4px;
    right: 5px;
  }
}

table.MuiTable-root [class*="MUIDataTableBodyRow-responsiveStacked-"] td {
  flex-basis: 40%;
}

.MuiTextField-root,
.MuiFormControl-root {
  width: 100%;
}

.underlineLink {
  color: $textColor;
  padding-bottom: 2px;
  border-bottom: 1px solid $primaryColor;
}

.onlyMobile {
  display: none;
  @media (max-width: #{$breakpoint-md}) {
    display: block;
  }
  h1.Headline {
    font-size: 40px;
    line-height: 1.2;
  }
  .secondary.Headline {
    font-size: 25px;
  }
}

.notMobile {
  display: block;
  @media (max-width: #{$breakpoint-md}) {
    display: none;
  }
}

.Box {
  @media (max-width: #{$breakpoint-md}) {
    padding: 20px !important;
  }
}

.formError {
  color: #c02b33;
}

.colorful {
  background-color: $lightGreenBackground !important;
}

.MuiFormHelperText-contained {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.MuiButton-root.MuiButton-contained {
  padding: 8px 10px !important;

  @include xs {
    padding: 8px 20px !important;
  }
}

.swal2-styled.swal2-confirm {
  background: $secondaryColor !important;

  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}
