@import "../../variables";

.footer {
  background: #e4eff7;
  margin-top: 50px;
  font-family: $fontDefault;

  & > div {
    background: $secondaryColor;
    padding: 40px 0;
  }

  .footer-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0 18px;

    @include md {
      flex-direction: row;
    }
  }

  nav {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    color: #fff;

    @include md {
      display: block;
    }

    .mehrwerkHint {
      text-align: center;

      @include sm {
        text-align: right;
      }
    }

    a {
      margin: 0;

      &:after {
        content: "|";
        margin: 10px;
        color: #fff;
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }

  a {
    color: #fff;
    margin: 10px;
    text-decoration: none;
  }
}
