.Registration {
  .MuiFormControl-root {
    width: 100%;

    .MuiFormControlLabel-root {
      align-items: baseline;

      .MuiCheckbox-root {
        top: 5px;
      }
    }

    .MuiFilledInput-root {
      background-color: #f7f7f7;
    }
  }
}
